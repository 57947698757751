@import 'src/assets/vars';

.loan-officer-card {
  display: flex;
  align-items: center;
  justify-self: center;
  margin: 0 auto;
  width: -moz-fit-content;
  width: fit-content;

  img {
    vertical-align: middle;
    height: 52px;
    width: 52px;
    margin-right: 5px;
    border-bottom-left-radius: 15%;
    border-top-left-radius: 15%;
    object-fit: cover;
  }
  .stack {
    text-align: left;
    font-size: 12px;
    height: 58px;

    span {
      width: 100%;
      display: block;

      svg,
      strong {
        color: $fmmred;
        margin-right: 5px;
      }
    }
  }
}
