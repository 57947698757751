@import 'src/assets/vars';

.app-header {
  .workstate {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    z-index: 999;
  }
  .header-top {
    background: $background-2;
    color: $black-1;
    border-bottom: 1px solid $black-5;

    & > .flex-container {
      display: flex;
    }
    .lo-info-card {
      margin-right: 14px;
    }
    .logo {
      width: 200px;
      margin: 8px 0 0 0;
    }
  }

  &.mobile {
    .logo {
      width: 113px;
      height: 25px;
      margin: 0 auto;
    }
    .user-wrapper {
      .user {
        margin-left: 0;
      }
    }
  }
}
