@import 'src/assets/vars';

.app-footer {
  .footer-content {
    margin-top: 11px;
    justify-content: space-between;

    .address {
      white-space: pre-wrap;
      min-height: 60px;
      font-size: 11px;
      color: $black-1;
    }
    .bugReport {
      flex: none;
      align-self: center;
    }
    .links {
      align-items: center;

      .social-media {
        .sm-item {
          width: 32px;
          height: 32px;
          margin: 0 5px;
          object-fit: contain;
        }
      }
      .equal-housing {
        width: 44px;
        height: 42px;
        object-fit: contain;
        margin: 0 15px 0 19px;
      }
      .bbb {
        width: 123px;
        object-fit: contain;
        margin-right: 7px;
      }
      .bbb-info {
        white-space: pre-wrap;
        width: 88px;
        font-size: 10px;
        a {
          color: #0164a4;
        }
      }
    }
  }
  .contact-info {
    font-size: 11px;
    margin-top: 8px;

    a {
      color: $accent-1;
    }
  }
  .browser {
    margin-bottom: 36px !important;
  }
  .mobile {
    margin-bottom: 14px !important;
    text-align: center;

    .footer-content {
      flex-direction: column;
      align-items: center;

      .social-media {
        margin: 6px 10px;
      }
      .bbb {
        width: 100px;
      }
      .bbb-info {
        text-align: left;
      }
    }
  }
}
