@import 'src/assets/vars';

.fm-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: alpha(opacity=80);
  background: $white-1;
  z-index: 9999;

  & > img {
    height: 100px;
    width: 100px;
  }
  & > .loader-message {
    color: $black-1;
    margin-top: 14px;
    font-size: 16px;
  }
  & > .pre-wrap {
    text-align: center;
    white-space: pre-wrap;
  }
}
