@import 'src/assets/vars';

.error-boundary {
  padding: 0 0 14px 0;

  h1 {
    font-weight: normal;
    font-size: 1.2rem;
    width: 100%;
    height: 30px;
    line-height: 30px;
    @include ellip;
    background: $red;
    color: white;
    padding: 0 14px;
    text-align: left;
  }
  h2 {
    font-weight: normal;
    font-size: 0.9rem;
    width: 100%;
    color: $black-2;
    padding: 0 14px;
    margin: 0;
    text-align: left;
  }
  p {
    white-space: pre;
    padding: 6px 14px 0 0;
    font-family: monospace;
  }
}
