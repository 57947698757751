$shadow-0: 0 1.5px 4px rgba(0, 0, 0, 0.12), 0 1.5px 6px rgba(0, 0, 0, 0.06);
$shadow-1: 0 1.5px 4px rgba(0, 0, 0, 0.24), 0 1.5px 6px rgba(0, 0, 0, 0.12);
$shadow-1-inset: inset 0 1.5px 4px rgba(0, 0, 0, 0.24),
  inset 0 1.5px 6px rgba(0, 0, 0, 0.12);
$shadow-2: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
$shadow-2-inset: inset 0 0 4px rgba(0, 0, 0, 0.14),
  inset 0 4px 8px rgba(0, 0, 0, 0.28);
$shadow-3: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
$shadow-3-inset: inset 0 8px 17px 0 rgba(0, 0, 0, 0.2),
  inset 0 6px 20px 0 rgba(0, 0, 0, 0.19);
$shadow-4: 0 10px 20px rgba(0, 0, 0, 0.22), 0 14px 56px rgba(0, 0, 0, 0.25);
$shadow-5: 0 15px 24px rgba(0, 0, 0, 0.22), 0 19px 76px rgba(0, 0, 0, 0.3);

$transition: cubic-bezier(0.23, 0.54, 0.19, 0.99);
$transition-2: cubic-bezier(0.08, 0.54, 0.45, 0.91);
$transition-bounce: cubic-bezier(0.175, 0.885, 0.32, 1.275);

$black-1: rgba(0, 0, 0, 0.87);
$black-2: rgba(0, 0, 0, 0.54);
$black-3: rgba(0, 0, 0, 0.38);
$black-4: rgba(0, 0, 0, 0.12);
$black-5: rgba(0, 0, 0, 0.07);
$black-6: rgba(0, 0, 0, 0.04);

$white-1: white;
$white-2: rgba(255, 255, 255, 0.75);
$white-3: rgba(255, 255, 255, 0.35);
$white-4: rgba(255, 255, 255, 0.1);
$white-5: rgba(255, 255, 255, 0.03);

$text-dark-1: $black-1;
$text-dark-2: $black-2;
$text-dark-3: $black-3;
$text-dark-4: $black-4;
$text-light-1: $white-1;
$text-light-2: $white-2;
$text-light-3: $white-3;
$text-light-4: $white-4;

$background-1: white;
$background-2: #f2f2f2;
$background-3: #e3e3e3;

$fmmred: #da5858;

$accent-1: #0164a4;
$accent-1-dark: #0064a4;
$accent-2: #0da8d2;
$accent-3: #da5858;

$list-item-hover-background: transparentize($accent-2, 0.9);
$list-item-hover-background-active: transparentize($accent-2, 0.7);

$red: #f34747;
$orange: #ff7a22;
$yellow: #e6d013;
$green: #4ed142;
$blue: #0da8d2;

$pending-changes-background: #ffffd9;
$new-content-background: #f0fcff;

$font-size: 13pt;
$font-size-small: 11pt;

$small-break: 640px;
$medium-break: 910px;
$large-break: 1200px;

$breakpoints: (
  'small': (
    max-width: $small-break,
  ),
  'medium': (
    max-width: $medium-break,
  ),
  'large': (
    max-width: $large-break,
  ),
) !default;
$breakpoints-min: (
  'small': (
    min-width: $small-break,
  ),
  'medium': (
    min-width: $medium-break,
  ),
  'large': (
    min-width: $large-break,
  ),
) !default;

@mixin break($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  } @else {
    @media (max-width: $breakpoint) {
      @content;
    }
  }
}

@mixin break-min($breakpoint) {
  @if map-has-key($breakpoints-min, $breakpoint) {
    @media #{inspect(map-get($breakpoints-min, $breakpoint))} {
      @content;
    }
  } @else {
    @media (min-width: $breakpoint) {
      @content;
    }
  }
}

@mixin ellip() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
