@import 'src/assets/vars';

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  // background: $background-1;
  // font-size: 11pt;
  // color: $text-dark-1;
  // overflow: hidden;
  // font-family: 'Lato', arial, sans-serif;
  font-weight: 400;
}

::selection {
  color: $text-light-1;
  background: $accent-2;
}

.app {
  .scroller {
    // height: calc(100% - 64px);
    // overflow-y: auto;
    z-index: 0;
  }
}

/* * {
  box-sizing: border-box !important;
  position: relative;
} */

.flex-container {
  display: flex;
  & > * {
    flex: none;
  }
  &.flex-center {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &.flex-horizintal {
    flex-direction: row;
  }
  &.flex-vertical {
    flex-direction: column;
  }
  &.flex-fill {
    width: 100%;
  }
  &.flex-space-between {
    justify-content: space-between;
  }
  .flex {
    display: inline-block;
    flex: 1 1 0%;

    &.flex-container {
      display: inline-flex;
    }
    &.flex-vertical-align {
      align-items: center;
    }
  }
}

svg[role='presentation'] {
  height: 24px;
  width: 24px;
  vertical-align: middle;
}

// hide Powered by Google
// https://stackoverflow.com/questions/12327651/how-to-remove-the-powered-by-google-logo-in-the-google-map-autocomplete-dropdo
.pac-container:after {
  background-image: none !important;
  height: 0px;
}

.savestatus-snackbar-content {
  padding: 8px 14px;

  span {
    margin-left: 14px;
    line-height: 32px;
  }
}
